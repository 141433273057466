.footer, .header {
  background: $dark;

  .navbar {
    padding: 0;
  }
}

.bordered {
  &__light {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      background: $white;
      height: 2px;
      width: 25%;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.nae-item__img:before,
.nae-item__date__wrap,
.nae-item .h5 {
  transition: all 0.3s ease-in-out;
}

[class$="link"] {
  transition: all 0.1s ease-in-out;
}

.link-default{
  color: $dark;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $red;
  }
}

.link-underline {
  text-decoration: underline;
}

@include col-x-list;

.additional {
  &__content-title {
    @include margin(60px, 0px, 40px, 0px);
  }
}

.not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}
