.exhibitions {
    &__wrap {
        @include padding(100px, 0px, 100px, 0px);

    }

    &__hero {
        &-wrap {
            .paragraph-2 {
                @include margin(60px, 0px, 60px, 0px);
            }
        }
    }

    &-page {
        &__wrap {
            .hero-inner__wrap {
                .paragraph-1 {
                    @include media-breakpoint-down(md) {
                        @include font-size(22px);
                        line-height: 1.6;
                    }
                }
            }

            .back-link {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    &__dark {
        @include padding(52px, 0px, 62px, 0px);
        background: $dark;
        color: #FFFFFF;
    }

    &__temporary {
        @include padding(100px, 0px, 60px, 0px);

        &__subtitle {
            @include margin(40px, 0px, 30px, 0px);
            @include font-size(19px);
            font-family: $font-acari-bold;
            color: $dark;
            letter-spacing: 0.5px;
        }
    }

    &__archive {
        .accordion__wrap {
            .btn-collapse {
                margin: 0;
                font-weight: bold;
                text-transform: uppercase;
                color: $dark;
            }
        }

        .accordion__arrow {
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }

        &__subtitle {
            margin-top: 5px;
            color: $dark;
        }
    }

    .accordion__wrap__exhibitions {
        &.accordion__wrap__active {

            .card-header {
                border-bottom: 1px solid rgba(39, 35, 35, 0.2);
            }
        }

        .card-body {
            padding-bottom: 0;
        }
    }

}
