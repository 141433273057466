.list {
	&__default {

		li,
		&__title {
			letter-spacing: 0.01em;
			color: $dark;
		}

		li {
			font-family: $font-acari-regular;
			@include font-size(17px);
			@include line-height(25px);
		}

		&__title {
			font-family: $font-acari-bold;
			@include font-size(24px);
			@include line-height(28px);
			@include margin(0px, 0px, 10px, 0px);

			@include media-breakpoint-down(md) {
				@include font-size(22px);
			}
		}

		ul + &__title {
			@include margin(40px, 0px, 10px, 0px);
		}
	}
}

.administrative__list-type{
	@include margin(30px, 0px, 0px, 0px);
	@include padding(0px, 0px, 0px, 40px);


	li {
		@include padding(0px, 0px, 20px, 0px);
	}
}

.administrative__list-dots li {
	list-style: disc;
}

.administrative__list-numbers li {
	list-style: decimal;
}
