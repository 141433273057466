.accordion {
    &__wrap {
        &:not(.accordion__wrap__exhibitions) {
            border-bottom: 1px solid $dark-02;
        }


        &:not(.accordion__wrap__exhibitions):hover {
            background: $gray;
        }

        .card-header {
            @include padding(30px, 0px, 25px, 0px);
            cursor: pointer;

            .h3,
            .paragraph-3 {
                transition-duration: 0.3s;
            }

            &:hover {
                &,
                .paragraph-3 {
                    color: $red;
                }

                svg {
                    fill: $red
                }
            }
        }

        .card-body {
            @include padding(15px, 0px, 40px, 0px);

            .text-block__wrap {
                display: block;
            }

            .text-block__wrap + .text-block__wrap {
                margin-top: 30px;
            }
        }

        &__active {
            &:not(.accordion__wrap__exhibitions) {
                background-color: $gray;

            }

            .accordion__arrow svg {
                transform: rotate(-90deg) translateY(0);
            }
        }

        .links {
            display: inline-block;
            text-transform: uppercase;
            text-decoration: underline;
            line-height: 1.7rem;
            color: $dark;

            &:hover {
                color: $red;
            }
        }
    }

    &__arrow {
        text-align: right;
        margin-right: 7px;

        svg {
            transform: rotate(90deg) translateY(0);
            width: 8px;
            fill: $dark;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }

        &__download {
            text-align: right;

            svg {
                width: 20px;
                fill: $dark;
            }
        }
    }
}
