.archive {
    background-color: $dark;
    color: $white;

    .h1 {
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(lg){
            text-align: center;
        }
    }

    .paragraph-2 {
        margin-bottom: 1.25rem;
        @include media-breakpoint-up(lg){
            @include font-size(20px);
            @include line-height(28px);
        }
    }

    &__container {
        padding-top:  8.125rem;
        padding-bottom:  7.8125rem;
    }

    &__list {
        margin-top: 1.25rem;

        &-item {
            margin-left: 1.25rem;
            list-style-type: disc;
            margin-bottom: 20px;
            font-family: $font-acari-regular;
            @include font-size(16px);
            opacity: 0.8;
        }
    }
}
