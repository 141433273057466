.mt-40 {
  @include margin(40px, 0px, 0px, 0px);
}
.mt-60 {
  @include margin(60px, 0px, 0px, 0px);
}
.mt-120 {
  @include margin(120px, 0px, 0px, 0px);
}

.pl-5px {
  padding-left: 5px;
}

.justify-around {
  justify-content: space-around;
}

.wpc-paragraphs-margin p {
  @include margin(0px, 0px, 30px, 0px);
}

.link {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}
