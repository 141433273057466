.dropdown {
  .dropdown-menu {
    @include padding(7.5px, 0px, 15px, 0px);
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 1px;


    .dropdown-item.nav__secondary-link {
      @include padding(7.5px, 20px, 7.5px, 20px);

      &:hover {
        background: transparent;
        color: $red !important;
      }
    }
  }

  &-lang {
    .dropdown-menu {
      padding: 0;
      min-width: auto;

      .dropdown-item {
        @include padding(10px, 15px, 10px, 15px);
      }

      .thumbnail-image {
        @include width(25px)
      }
    }
    .display-flag {
      @include width(12px);
      font-family: acari_sansregular, sans-serif;
      font-weight: 500;

      @include media-breakpoint-down(lg) {
        color: rgba(255, 255, 255, 0.85);
        @include width(23px);
      }

      .thumbnail-image {
        width: 100%;
      }
    }
  }

  &-item {
    &.active {
      background-color: transparent;
      color: $red;
      &.nav__secondary-link {
        color: $red !important;
        background-color: transparent;
      }
    }
    &:active {
      color: $red;
      background-color: $dark;
    }

   
  }
}
