@import '../fonts/CormorantInfant/stylesheet.css';
@import '../fonts/FiraSans/stylesheet.css';
@import '../fonts/AcariSans/stylesheet.css';

$font-cormorant-regular: 'cormorant_infantregular', sans-serif;
$font-cormorant-bold: 'cormorant_infantbold', sans-serif;
$font-cormorant-light: 'cormorant_infantlight', sans-serif;

$font-fira-bold: 'fira_sansbold', sans-serif;

$font-acari-regular: 'acari_sansregular', sans-serif;
$font-acari-bold: 'acari_sansbold', sans-serif;
$font-acari-semibold: 'acari_sanssemibold', sans-serif;
$font-acari-medium: 'acari_sansmedium', sans-serif;


