.inner-helper-page {
  @include padding(60px, 0px, 100px, 0px);

  &__title {
    @include margin(60px, 0px, 0px, 0px);
  }

  &__subtitle {
    @include margin(30px, 0px, 0px, 0px);
    .subtitle {
      text-align: left;
    }
  }

  &__text {
    @include margin(26px, 0px, 0px, 0px);
  }

  &__author {
    @include margin(10px, 0px, 0px, 0px);


    &_wrap {
      @include margin(40px, 0px, 0px, 0px);
    }
  }
}
