/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 19, 2020 */



@font-face {
    font-family: 'cormorant_infantbold';
    src: url('cormorantinfant-bold-webfont.woff2') format('woff2'),
         url('cormorantinfant-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantbold_italic';
    src: url('cormorantinfant-bolditalic-webfont.woff2') format('woff2'),
         url('cormorantinfant-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantitalic';
    src: url('cormorantinfant-italic-webfont.woff2') format('woff2'),
         url('cormorantinfant-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantlight';
    src: url('cormorantinfant-light-webfont.woff2') format('woff2'),
         url('cormorantinfant-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantlight_italic';
    src: url('cormorantinfant-lightitalic-webfont.woff2') format('woff2'),
         url('cormorantinfant-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantmedium';
    src: url('cormorantinfant-medium-webfont.woff2') format('woff2'),
         url('cormorantinfant-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantmedium_italic';
    src: url('cormorantinfant-mediumitalic-webfont.woff2') format('woff2'),
         url('cormorantinfant-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantregular';
    src: url('cormorantinfant-regular-webfont.woff2') format('woff2'),
         url('cormorantinfant-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantsemibold';
    src: url('cormorantinfant-semibold-webfont.woff2') format('woff2'),
         url('cormorantinfant-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'cormorant_infantSBdIt';
    src: url('cormorantinfant-semibolditalic-webfont.woff2') format('woff2'),
         url('cormorantinfant-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}