/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 18, 2017 */



@font-face {
    font-family: 'fira_sansbold';
    src: url('firasans-bold-webfont.woff2') format('woff2'),
         url('firasans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fira_sans_condensedsemibold';
    src: url('firasanscondensed-semibold-webfont.woff2') format('woff2'),
         url('firasanscondensed-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fira_sanslight';
    src: url('firasans-light-webfont.woff2') format('woff2'),
         url('firasans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fira_sansmedium';
    src: url('firasans-medium-webfont.woff2') format('woff2'),
         url('firasans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fira_sansregular';
    src: url('firasans-regular-webfont.woff2') format('woff2'),
         url('firasans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}