.navbar-dark {
  .navbar-nav .nav {
    &__main-link,
    &__main-link > .nav-link {
      color: rgba(255, 255, 255, 0.85);

      &::after {
        transition: all 0.1s ease-in-out;
      }

      &:hover,
      &.show.dropdown > a {
  
        &,
        &::after {
          color: $gray-hover !important;
        }
      }

      &:active,
      &.active,
      &.active > a,
      &:active > a {
        color: $red;
      }
    }

    &__secondary-link,
    &__secondary-link > .nav-link {
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        color: $gray-hover;
      }

      &:active,
      &:active > a {
        color: $red;
      }
    }
  }
}


.navbar-nav .nav {
  &__main-link {
    border-top: 1px solid transparent;
    @include padding(14px, 0px, 14px, 0px);
    font-family: $font-acari-regular;
    font-weight: 500;
    @include font-size(14px);
    @include line-height(14px);
    letter-spacing: 0.01em;
    text-transform: uppercase;

    .nav-link {
      padding: 0;
    }

    &.active {
     border-top-color: $red; 
    }
  }

  &__secondary-link {
    @include padding(14px, 0px, 14px, 0px);
    font-family: $font-acari-regular;
    font-weight: 500;
    @include font-size(13px);
    @include line-height(14px);

    .nav-link {
      padding: 0;
    }
  }
}


.footer .navbar-expand,
.navbar-expand-xl {
  .navbar-nav .nav {
    &__main-link.dropdown-lang,
    &__main-link.dropdown,
    &__secondary-link {
      @include padding(0px, 0px, 0px, 0px);
    }
    &__main-link,
    &__main-link.dropdown-lang .nav-link,
    &__main-link.dropdown .nav-link {
      @include padding(14px, 0px, 14px, 0px);
    }
  }
}
