.section {
	&-team {
		@include media-breakpoint-up(lg) {
			@include padding(70px, 0px, 0px, 0px);
		}
		background-color: $gray;


		&__col {
			@include margin(0px, 0px, 30px, 0px);
		}

		&__title__wrap {
			@include margin(0px, 0px, 40px, 0px);
			color: $dark;

			@include media-breakpoint-down(md) {
				.h1	{
				text-align: center;
				}

				.h2 {
					@include font-size(30px);
					text-align: center;
				}
			}

		}

		&__container {
			&-wrap {
				@include padding(60px, 0px, 60px, 0px);
				border-bottom: 1px solid $dark-02;
			}
		}

		.loading {
			color: $dark-02;
		}

		.card__wrap {
			height: 100%;
		}

		.card__media-vertical {
			justify-content: space-between;
		}

		@include media-breakpoint-down(md) {
			.card {
				@include padding(50px, 25px, 40px, 25px);

				&__media-horizontal,
				&__main-info {
					flex-direction: column;
				}

				&-body__wrap {
					padding-left: 0 !important;
				}

				&__media-horizontal {
					align-items: center;
				}

				&-body__label,
				.link-wrap {
					text-align: center;
				}
			}
		}
	}
}
