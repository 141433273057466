.laszlo-nagy__page {
	.hero-collection .hero {
		padding-top: 17.5rem;
		padding-bottom: 6.625rem;
		background-position: center;
	}


	.section-carousel {
		@include padding(120px, 0px, 40px, 0px);
		
		@include media-breakpoint-down(md) {
			@include padding(60px, 0px, 40px, 0px);
		}
	
		.nae-container {
			@include padding(0px, 0px, 10px, 0px);
		}

		.paragraph-2 {
			@include margin(36px, 0px, 0px, 0px);
		}
	}

	&__contact-section {
		.title {
			font-family: $font-acari-regular;
			@include font-size(20px);
			@include line-height(27px);
			margin-bottom: 30px;
		}
		.list {
			margin-bottom: 50px;
			& > li {
				font-family: $font-acari-regular;
				@include font-size(17px);
				@include line-height(27px);
			}
		}
	}
}
