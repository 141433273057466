.navbar-toggler {
	&.collapsed {
		//margin-top: -1rem;
	}

	margin-top: -0.8rem;
	margin-right: -1rem;
	border: none;
	padding: 0;
	z-index: 99;

	&:focus {
		outline: none;
		border: none;
	}
}

.navbar-collapse {
	@include media-breakpoint-down(lg) {
		padding: 0 1.5rem;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgb(39, 35, 35);
	}
}

.hamburger {
	font: inherit;
	display: inline-block;
	overflow: visible;
	margin: 0;
	padding: 15px;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: .15s;
	transition-property: opacity, filter;
	text-transform: none;
	color: inherit;
	border: 0;
	background-color: transparent;

	&-box {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 24px;
	}


	&--slider &-inner {
		top: 2px;
	}

	&-inner {
		top: 50%;
		display: block;
		margin-top: -2px;
	}


	&--slider &-inner:before {
		top: 5px;
		transition-timing-function: ease;
		transition-duration: .15s;
		transition-property: transform, opacity;
	}

	&-inner:before {
		top: -10px;
	}

	&-inner:after, &-inner:before {
		display: block;
		content: "";
	}

	&-inner, &-inner:after, &-inner:before {
		position: absolute;
		width: 20px;
		height: 2px;
		transition-timing-function: ease;
		transition-duration: .15s;
		transition-property: transform;
		border-radius: 4px;
		background-color: #fff;
	}

	&--slider &-inner:after {
		top: 10px;
	}

	&-inner:after {
		bottom: -10px;
	}

	&--slider.is-active &-inner {
		transform: translate3d(0, 17px, 0) rotate(45deg);
	}

	//&--slider.is-active &-inner:after {
	//	top: auto;
	//	bottom: -20px;
	//}

	&.is-active &-inner, &.is-active &-inner:after, &.is-active &-inner:before {
		background-color: #fff;
	}

	&--slider.is-active &-inner:before {
		transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}

	&.is-active &-inner, &.is-active &-inner:after, &.is-active &-inner:before {
		background-color: #fff;
	}

	&--slider.is-active &-inner:after {
		transform: translate3d(0, -10px, 0) rotate(-90deg);
	}

	&.is-active &-inner, &.is-active &-inner:after, &.is-active &-inner:before {
		background-color: #fff;
	}

}


.cross-fade-leave {
	opacity: 1;
}
.cross-fade-leave.cross-fade-leave-active {
	opacity: 0;
	transition: opacity 1s ease-in;
}

.cross-fade-enter {
	opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
	opacity: 1;
	transition: opacity 1s ease-in;
}

.cross-fade-height {
	transition: height 0.5s ease-in-out;
}
