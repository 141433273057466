$red: #EB1D01;
$dark: #272323;
$dark-06: rgba(39, 35, 35, 0.6);
$dark-08: rgba(39, 35, 35, 0.8);
$dark-02: rgba(39, 35, 35, 0.2);
$dark-09: rgba(39, 35, 35, 0.9);
$gray: #F9F9F9;
$gray-c4: #C4C4C4;
$gray-hover: #828282;
$white: #FFFFFF;
