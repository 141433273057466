.nae-item {
  &__img__wrap {
    @include margin(0px, 0px, 15px, 0px);
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      @include margin(0px, 0px, 20px, 0px);
    }
  }

  .h5 {
    color: $dark;
  }

  &__date {
    &__wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      @include padding(35px, 28px, 24px, 12px);
      background-color: $dark;
      z-index: 2;
    }

    &__wrap__two-dates {
      display: flex;
      justify-content: space-between;
    }
    &-day {
      font-family: $font-acari-regular;
      font-weight: 300;
      @include font-size(54px);
      @include line-height(54px);
      letter-spacing: -0.02rem;
      color: $white;
      text-align: center;
    }

    &-month {
      text-align: center;
      font-family: $font-acari-bold;
      @include font-size(11px);
      @include line-height(14px);
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
    }
  }

  &__img:before {
    content: " ";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
    z-index: 1;
  }

  &__vertical {
    .nae-item__img {
      .img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 0;
      }

      &:after {
        padding-top: 66.25%;
        display: block;
        content: "";
      }
    }

    &__wrap {
      @include media-breakpoint-down(sm) {
        &:not(:first-child) {
          @include margin(60px, 0px, 0px, 0px);
        }
      }
    }
  }

  &__vertical:hover {
    .nae-item__img:before {
      top: 0;
    }

    .nae-item__date__wrap {
      background-color: $red;
    }

    .h5 {
      text-decoration: underline;
    }
  }

  &__horizontal {
    width: 100%;

    .nae-item__wrap {
      display: flex;
      flex-direction: row;
    }

    .link-img:hover {
      .nae-item__date__wrap {
        background-color: $red;
      }

      .nae-item__img:before {
        top: 0;
      }
    }

    .nae-item__img .img {
      width: 100%;
      height: auto;
    }

    .link-title:hover {
      text-decoration: none;

      .h3 {
        text-decoration: underline;
      }
    }

    .h3,
    .h3 > .link {
      color: $dark;

      &:hover {
        text-decoration: underline;
      }
    }

    .a {
      margin-top: 2rem;

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }
  }

  &__horizontal + &__horizontal {
    @include margin(40px, 0px, 0px, 0px);

    @include media-breakpoint-down(md) {
      @include margin(60px, 0px, 0px, 0px);
    }
  }

  &__article-date {
    font-family: $font-acari-bold;
    @include font-size(12px);
    @include line-height(16px);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(39, 35, 35, 0.5);

    &__wrap {
      @include margin(0px, 0px, 15px, 0px);

      @include media-breakpoint-down(md) {
        @include margin(0px, 0px, 10px, 0px);
      }
    }
  }

  &__date_post {
    @include margin(10px, 0px, 0px, 0px);
    display: flex;
    flex-direction: row;
  }
}

.wpc-paragraph__main-content {
  img, video{
    max-width: 100%;
    height: auto;
  }

  iframe {
      max-width: 100%;
  }
}