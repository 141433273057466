.socials {
    margin-top: auto;
    display: flex;
    flex-direction: row;

    &-item {
        @include margin(0px, 8px, 0px, 0px);

        svg {
            fill: $dark;
        }

        .react-share__ShareButton {
            &:focus {
                outline: none;

                svg {
                    fill: $red;
                }
            }
        }

        &:hover {
            svg {
                fill: $red;
            }
        }
    }

    &-label {
        margin-bottom: 10px;
        font-family: $font-acari-regular;
        @include font-size(14px);
        @include line-height(12px);
        letter-spacing: -0.005em;
        color: rgba(39, 35, 35, 0.7);
    }

    &__top-indent {
        margin-top: 1.875rem;


        @include media-breakpoint-down(md) {
            @include margin(60px, 0px, 0px, 0px);
        }
    }
}
