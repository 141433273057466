.info-line {
	@include padding(18px, 0px, 18px, 0px);

	@include media-breakpoint-down(md) {
		@include padding(15px, 0px, 15px, 0px);
	}

	& + & {
		@include margin(30px, 0px, 0px, 0px);
	}

	&__red {
		background-color: $red;

		.info-line__text {
			color: $white;
		}

		.cta_outline__light:hover {
			color: $dark;
			fill: $dark;
			border-color: $dark;
		}

	}

	&__bordered__white {
		background: #FFFFFF;
		border: 1px solid rgba(39, 35, 35, 0.25);
		border-radius: 0;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;

		.h2, .h5 {
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}

		.info-line__text,
		.info-line__titled__text {
			color: $dark;
		}

		.cta_outline__light:hover {
			color: $dark;
			fill: $dark;
			border-color: $dark;
		}

		&:hover {
			border-color: $dark;
			background: $gray;

			.h2, h5 {
				color: $red;
			}
		}

	}

	&__content-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;


		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: flex-start;

			.info-line__item__inactive-months {
				display: none;
			}

			.info-line__item + .info-line__item {
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	&__text {
		@include font-size(15px);
		@include line-height(16px);
		font-family: $font-acari-regular;

		&-bold {
			font-family: $font-acari-bold;
		}

		@include media-breakpoint-down(md) {
			@include font-size(14px);
		}

		&__get-working-time {
			margin-left: 6px;
			@include media-breakpoint-down(md) {
				margin-top: 30px;
				margin-left: 0;
				display: block;
			}
		}

		&__working-days {
			@include media-breakpoint-down(md) {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				span:last-child {
					width: auto;
					margin-left: auto;
				}
			}
		}
	}


	&__titled {
		@include padding(35px, 65px, 35px, 65px);

		&__text {
			font-family: $font-cormorant-bold;
			@include font-size(36px);
			@include line-height(38px);
			letter-spacing: -0.01em;
			text-transform: initial;
		}

		&__item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&.is-small {
			@include padding(30px, 40px, 23px, 40px);
		}
	}
}

.home-page__info-column {

	.link {
		margin: 0 2rem;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column-reverse;
		.nae-container {
			.link {
				@include margin(30px, 0px, 0px, 0px);
			}

			.nae__title-line {
				@include margin(0px, 0px, 30px, 0px);
			}

			.h1 {
				text-align: left;
			}
		}
	}
}
