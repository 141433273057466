body {
  font-size: 100%;
  color: $dark;
}

html, body, #root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

p {
  margin: 0;
}

main {
  flex-grow: 1;
}

header, main, footer {
  flex-shrink: 0;
}

a:hover {
  text-decoration: none;
}


ol, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg {
  vertical-align: baseline;
}
