.services-page {
    .nae-container {
        @include media-breakpoint-up(lg) {
            @include padding(90px, 0px, 90px, 0px)
        }
    }

    .info-column {
        p {
            margin-bottom: 1rem;
        }
    }

    .section-routes .info-column {
        padding-bottom: 2.5rem;
    }

    .section-routes .h5__sub {
        font-weight: bold;
    }

    .section {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    .routes-list__title {
        margin-bottom: 1.25rem;
        @include font-size(17px);
        font-family: "acari_sansregular", sans-serif;
        @include line-height(27px);
        color: $dark;
    }

    .hero-bg {
        height: auto;
    }

    .routes-list__item {
        margin-bottom: 1.5rem;
    }

    .routes-list__title {
        margin-bottom: 1.25rem;
    }

    .routes-list__item-title {
        margin-bottom: 0.625rem;
        @include font-size(24px);
        @include line-height(27px);
    }

    .routes-call {
        @include font-size(17px);
        @include line-height(27px);
        margin-top: 5rem;

        span {
            font-family: $font-acari-bold;
        }

        a {
            color: inherit;


            &:hover {
                text-decoration: underline;
            }
        }
    }

    .list-services {
        margin-top: 1.25rem;
    }

    .list-services__item {
        margin-bottom: 1.25rem;
        font-family: $font-acari-bold;
        @include font-size(17px);
        @include line-height(25px);
        color: #524F4F;
    }

    .section-services {
        .nae-container {
            padding-bottom: 2.5rem;
        }

        &__card-info__price-link {
            margin-top: 7.5rem;

            .h5__sub {
                font-weight: 600;
            }

            .info-line__titled__item {
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: flex-end;
                }

            }
        }
    }

    &__main {
        .section:last-child {
            padding-bottom: 6.25rem;
        }
    }
}


