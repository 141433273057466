/* ==========================================================================
   BOOTSTRAP overwriting variables
*/
$tooltip-bg: #F9F9F9;
$tooltip-color: #272323;
$tooltip-padding-y: 1rem;
$tooltip-padding-x: 1rem;
$tooltip-max-width: 300px;
//$breadcrumb-divider:                quote("\\") !default;
//$grid-gutter-width: 30px !default;
$container-max-widths: (
        sm: 500px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;
//
//$custom-file-text: (
//        en: "Browse...",
//        bg: "Избери..."
//);
