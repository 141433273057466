/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 22, 2020 */



@font-face {
    font-family: 'acari_sansbold';
    src: url('acarisans-bold-webfont.woff2') format('woff2'),
         url('acarisans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sansbold_italic';
    src: url('acarisans-bolditalic-webfont.woff2') format('woff2'),
         url('acarisans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sansitalic';
    src: url('acarisans-italic-webfont.woff2') format('woff2'),
         url('acarisans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sanslight';
    src: url('acarisans-light-webfont.woff2') format('woff2'),
         url('acarisans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sanslight_italic';
    src: url('acarisans-lightitalic-webfont.woff2') format('woff2'),
         url('acarisans-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sansmedium';
    src: url('acarisans-medium-webfont.woff2') format('woff2'),
         url('acarisans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sansmedium_italic';
    src: url('acarisans-mediumitalic-webfont.woff2') format('woff2'),
         url('acarisans-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sansregular';
    src: url('acarisans-regular-webfont.woff2') format('woff2'),
         url('acarisans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sanssemibold';
    src: url('acarisans-semibold-webfont.woff2') format('woff2'),
         url('acarisans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'acari_sanssemibold_italic';
    src: url('acarisans-semibolditalic-webfont.woff2') format('woff2'),
         url('acarisans-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}