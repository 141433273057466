//Set a rem font size with pixel fallback
//Usage
//
//p {
//  @include font-size(14px)
//}

//Output
//p {
//  font-size: 14px; //Will be overridden if browser supports rem
//  font-size: 0.8rem;
//}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: calculateRem($size);
}

@mixin width($size) {
  width: calculateRem($size);
}

@mixin height($size) {
  height: calculateRem($size);
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: calculateRem($top);
  padding-right: calculateRem($right);
  padding-bottom: calculateRem($bottom);
  padding-left: calculateRem($left);
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: calculateRem($top);
  margin-right: calculateRem($right);
  margin-bottom: calculateRem($bottom);
  margin-left: calculateRem($left);
}


$columns: 20;

@mixin col-x-list {
  @for $i from 1 through $columns {
    .col-count-#{$i} {
      column-count: #{$i};
      column-gap: calc(190px / #{$i});
    }
  }
}

